<template>
  <b-modal
    id="modal-close-workspace"
    centered
    title="Close Workspace"
    ok-title="Close Workspace"
    ok-variant="danger"
    cancel-title="Dismiss"
    cancel-variant="outline-secondary"
    @ok="closeWorkspace"
  >
    <p class="mt-1">
      Are you sure you wish to close the current workspace?
    </p>
  </b-modal>
</template>

<script>
import router from '@/router'

export default {
  name: 'CloseModelWorkspace',
  methods: {
    closeWorkspace() {
      this.$store
        .dispatch('model/closeWorkspace')
        .then(() => {
          router.push({ name: 'model_browse' })
        })
    },
  },
}
</script>
