<template>
  <div
    class="app-content kontent"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <!--<slot name="breadcrumb">-->
      <!--  <app-breadcrumb />-->
      <!--</slot>-->

      <div class="content-body">

      <!--<transition-->
      <!--  :name="routerTransition"-->
      <!--  mode="out-in"-->
      <!--&gt;-->
      <slot />
      <!--</transition>-->
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  setup() {
    const { contentWidth } = useAppConfig()

    return {
      contentWidth,
    }
  },
}
</script>
