<template>
  <div id="AppBreadcrumb">
    <div
      v-if="$route.meta.titleHidden !== true && ($route.meta.breadcrumb || $route.meta.pageTitle)"
      class="w-100 mb-50 d-inline-flex justify-content-between content-header"
    >

      <!-- Content Left -->
      <div class="content-header-left">
        <!--  Unused-->
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
